import { PHONE_TYPE_MOBILE } from "@/constants/common";

export default {
  type: "contacts",
  company_name: null,
  firstname: null,
  lastname: null,
  email: null,
  phone: null,
  phone_extension: null,
  phone_type: PHONE_TYPE_MOBILE,
  other_phones: [],
  country: "Canada",
  state: "Quebec",
  city: null,
  zipcode: null,
  address: null,
  title: null,
  birthday: null,
  excerpt: "",
  relationshipNames: [
    "organization",
    "contactable",
    "tags",
    "allowedLocations",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  contactable: {
    type: null,
    id: null,
  },
  tags: [],
  allowedLocations: [],
};

<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-8">
              <h3 class="mb-0">
                {{ contact.name }}
                <base-button
                  v-if="$currentUserCan($permissions.PERM_EDIT_CONTACTS)"
                  type="primary"
                  icon
                  size="sm"
                  @click="editContact()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="$currentUserCan($permissions.PERM_DELETE_CONTACTS)"
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteContact()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>

              <notification-subscription
                :objectType="'contacts'"
                :objectId="contact.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <contact-view-global
                    :contact="contact"
                    @contactUpdated="get"
                  />
                </tab-pane>

                <tab-pane
                  title="files"
                  id="998"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
                >
                  <span slot="title">
                    <i class="ni ni-folder-17" />
                    {{ $t("COMMON.FILES") }}
                  </span>
                  <list-file-component :object="contact" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <contact-view-logs :contact="contact" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import defaultContact from "./defaultContact";
import ContactViewGlobal from "./partials/ContactViewGlobal.vue";
import ContactViewLogs from "./partials/ContactViewLogs.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ContactViewGlobal,
    ContactViewLogs,
    ListFileComponent,
    NotificationSubscription,
  },

  mixins: [],

  data() {
    return {
      contact: cloneDeep(defaultContact),
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("contacts/get", id);
        this.contact = this.$store.getters["contacts/contact"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editContact(row) {
      this.$router.push({
        name: "Edit Contact",
        params: { id: this.contact.id },
      });
    },

    async deleteContact() {
      const confirmation = await swal.fire({
        title: this.$t("CONTACTS.DELETE_THIS_CONTACT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("contacts/destroy", this.contact.id);
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("CONTACTS.CONTACT_DELETED"),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goBack() {
      this.$router.push({ name: "List Contacts" });
    },
  },
};
</script>
